import React from "react";
import { Route, Routes } from "react-router-dom";
import Auth from "src/pages/Auth";
import Footer from "src/pages/Footer";
import "src/App.css";
import "src/pages/Footer/Footer.css";
import Register from "src/pages/Auth/Register";
import VerifyOTP from "src/pages/Auth/VerifyOTP";
import ForgotPassword from "src/pages/Auth/ForgotPassword";
import EnterOTPForgotPassword from "src/pages/Auth/EnterOTPForgotPassword";
import NewPassword from "src/pages/Auth/NewPassword";
import Home from "src/pages/Home";
import HomeNotRegister from "src/pages/HomeNotRegister";
import NavBar from "src/pages/Navbar";
import ProductNotRegister from "src/pages/ProductNotRegister";
import ProductDetailNotRegister from "src/pages/ProductDetailNotRegister";
import LoadingNotRegister from "src/pages/LoadingNotRegister";
import NewDetailNotRegister from "src/pages/NewDetailNotRegister";
import AllProduct from "src/pages/AllProductNotRegister";

const AuthNavigator = () => {
  return (
    <div className="container-homepage">
      {/* <NavBar /> */}
      <Routes>
        <Route index path="/" element={<HomeNotRegister />} />
        <Route index path="/sign-in" element={<Auth />} />
        <Route index path="/sign-up" element={<Register />} />
        <Route index path="/verify-otp" element={<VerifyOTP />} />
        <Route index path="/forgot-password" element={<ForgotPassword />} />
        <Route index path="/new-password" element={<NewPassword />} />
        <Route
          index
          path="/otp-forgot-password"
          element={<EnterOTPForgotPassword />}
        />
        <Route index path="/product" element={<ProductNotRegister />} />
        <Route
          index
          path="/product/detail"
          element={<ProductDetailNotRegister />}
        />
        <Route index path="/loading" element={<LoadingNotRegister />} />
        <Route index path="/new-detail" element={<NewDetailNotRegister />} />
        <Route index path="/all-products" element={<AllProduct />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default AuthNavigator;
