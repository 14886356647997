import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "src/pages/Footer";
import Home from "src/pages/Home";
import NavBar from "src/pages/Navbar";
import "src/App.css";
import Profile from "src/pages/Profile";
import MyBank from "src/pages/MyBank";
import ProductDetail from "src/pages/ProductDetail";
import BuyProduct from "src/pages/BuyProduct";
import Cart from "src/pages/Cart";
import QRPayment from "src/pages/QRPayment";
import PaymentSuccess from "src/pages/PaymentSuccess";
import ListOrder from "src/pages/ListOrder";
import { useSelector } from "react-redux";
import { isProfile } from "../Redux/selector";
import ListLeftProfile from "src/pages/ListLeftProfile";
import MyAddress from "src/pages/MyAddress";
import OrderDetail from "src/pages/OrderDetail";
import Wallet from "src/pages/Wallet";
import Accumulation from "src/pages/Accumulation";
import Share from "src/pages/Share";
import WalletDetail from "src/pages/WalletDetail";
import NewDetail from "src/pages/NewDetail";
import Notification from "src/pages/Notification";
import Guide from "src/pages/Guide";
import GuideNewUser from "src/pages/GuideNewUser";
import Product from "src/pages/Product";
import Loading from "src/pages/Loading";
import AllProduct from "src/pages/AllProduct";

const MainNavigator = () => {
  const checkProfile = useSelector(isProfile);

  return (
    <div className="container-homepage">
      <NavBar />
      {checkProfile?.payload ? (
        <div className="container-profile">
          <div className="container-xxl">
            <div className="col col-lg-12 row pb-5">
              <ListLeftProfile />
              <Routes>
                <Route index path="/profile" element={<Profile />} />
                <Route index path="/my-bank" element={<MyBank />} />
                <Route index path="/my-address" element={<MyAddress />} />
                <Route index path="/list-order" element={<ListOrder />} />
                <Route index path="/order-detail" element={<OrderDetail />} />
                <Route index path="/wallet" element={<Wallet />} />
                <Route index path="/wallet-detail" element={<WalletDetail />} />
                <Route index path="/Accumulation" element={<Accumulation />} />
                <Route index path="/share" element={<Share />} />
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <Routes>
          <Route index path="/*" element={<Home />} />
          <Route index path="/product/detail" element={<ProductDetail />} />
          <Route index path="/buy-product" element={<BuyProduct />} />
          <Route index path="/cart" element={<Cart />} />
          <Route index path="/qr-payment" element={<QRPayment />} />
          <Route index path="/payment-success" element={<PaymentSuccess />} />
          <Route index path="/new-detail" element={<NewDetail />} />
          <Route index path="/notifications" element={<Notification />} />
          <Route index path="/guide" element={<Guide />} />
          <Route index path="/guide-new" element={<GuideNewUser />} />
          <Route index path="/product" element={<Product />} />
          <Route index path="/all-products" element={<AllProduct />} />
          <Route index path="/loading" element={<Loading />} />
        </Routes>
      )}
      <Footer />
    </div>
  );
};

export default MainNavigator;
